<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div>
      <!-- <div class="text-center">
      <h1 class="font-weight-bolder">
        DASHBOARD BY JOKER168S
      </h1>
    </div> -->
      <b-row class="match-height">
        <!-- <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit white">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>{{ Commas(today.total) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>{{ Commas(today.total) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>{{ Commas(today.total) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>{{ Commas(today.total) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>{{ Commas(today.total) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-white white ">
              <div class="p-1">
                <span>เครดิต</span>
                <h3>{{ Commas(today.total) }}</h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>เครดิตสมาชิก 0.00</small>
              </div>
            </div>

          </div>
        </b-col> -->
        <!-- ---------------- -->
        <b-col
          md="3"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-blue">
              <div class="p-1">
                <span>สมัครวันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.regis) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small> -</small>
                <small> -</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="3"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-pink">
              <div class="p-1">
                <span>Active วันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.nobet) }}
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <b-col
          md="3"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-orange">
              <div class="p-1">
                <span>เติมเงินวันนี้</span>
                <h3 class="text-white">
                  {{ Commas(today.total) }}<small>  บาท</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <!-- <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-yellow">
              <div class="p-1">
                <span>เติมเงินครั้งแรก</span>
                <h3>
                  -<small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>จำนวน - บาท</small>
              </div>
            </div>

          </div>
        </b-col> -->
        <b-col
          md="3"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-green">
              <div class="p-1">
                <span>ยอดถอนวันนี้</span>
                <h3 class="text-white">
                  0 <small> บาท</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>-</small>
              </div>
            </div>

          </div>
        </b-col>
        <!-- <b-col
          md="2"
          cols="12"
          class="mb-1"
        >
          <div>
            <div class="card-cradit-bluesea">
              <div class="p-1">
                <span>กงล้อ</span>
                <h3 class="text-white">
                  0 <small>คน</small>
                </h3>
              </div>
              <div class="card-b d-flex justify-content-between">
                <small>-</small>
                <small>จำนวน 0.00 บาท</small>
              </div>
            </div>

          </div>
        </b-col> -->
      </b-row>
      <!-- <b-row class="match-height">
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-with-area-chart
            icon="FileTextIcon"
            :statistic="`${Commas(today.total)} บาท`"
            statistic-title="ยอดรวมวันนี้"
          /> </b-col><b-col
          lg="3"
          sm="6"
        >
          <statistic-card-with-area-chart
            v-if="!show"
            icon="ArrowDownIcon"
            color="success"
            :statistic="`${Commas(today.deamount)} บาท`"
            statistic-title="ยอดฝากวันนี้"
            :chart-data="depositSeries"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-with-area-chart
            v-if="!show"
            icon="ArrowUpIcon"
            color="danger"
            :statistic="`${Commas(today.weamount)} บาท`"
            statistic-title="ยอดถอนวันนี้"
            :chart-data="withdrawSeries"
          />
        </b-col>
        <b-col
          lg="3"
          sm="6"
        >
          <statistic-card-with-area-chart
            v-if="!show"
            icon="GiftIcon"
            color="warning"
            :statistic="`${Commas(today.debet)} บาท`"
            statistic-title="ยอดแทงวันนี้"
            :chart-data="betSeries"
          />
        </b-col>
      </b-row> -->

      <b-row class="match-height">
        <b-col md="9">
          <b-overlay
            :show="showTable"
            variant="transparent"
            opacity="0.99"
            blur="5px"
            rounded="sm"
          >
            <template #overlay>
              <div class="text-center">
                <b-icon-controller
                  font-scale="3"
                  animation="cylon"
                />
                <p id="cancel-label">
                  กรุณารอสักครู่...
                </p>
              </div>
            </template>
            <b-card
              v-if="!showTable"
              no-body
            >
              <div>
                <div class="row mb-1 pt-1 pl-1">
                  <div class="col-2">
                    <b-form-select
                      v-model="selectedmonth"
                      :options="month"
                      value-field="value"
                      text-field="text"
                    />
                  </div>
                  <div class="col-2">
                    <b-form-select
                      v-model="selectedyear"
                      :options="year"
                      value-field="value"
                      text-field="text"
                    />
                  </div>
                  <div class="col-2">
                    <b-button
                      variant="gradient-primary"
                      @click="getMonthly"
                    >
                      <i class="fal fa-search-dollar" />
                      ค้นหา
                    </b-button>
                  </div>
                  <div class="col-6" />
                </div>

                <div class="wrap-table100">
                  <div class="table100 ver1">
                    <div class="table100-firstcol">
                      <table>
                        <thead>
                          <tr class="row100 head">
                            <th class="cell100 column1">
                              รายละเอียด/วัน
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              สมัครวันนี้
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              จำนวนฝากวันนี้
                            </td>
                          </tr>

                          <!-- <tr class="row100 body">
                          <td class="cell100 column1">
                            Active
                          </td>
                        </tr> -->

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              ยอดฝาก
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              ยอดถอน
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              ยอดรวม
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              ยอดแทง
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              จำนวนลูกค้าแทง
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              Facebook
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              Google
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              Youtube
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              TikTok
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              SMS
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              เพื่อนแนะนำ
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div class="wrap-table100-nextcols js-pscroll">
                      <div class="table100-nextcols">
                        <table>
                          <thead>
                            <tr class="row100 head">
                              <th
                                v-for="day in endDateTable"
                                :key="day"
                                class="cell100 column2"
                              >
                                {{ day }}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.regis"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.nodeposit"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <!-- <tr class="row100 body">
                            <td
                              v-for="item in monthly.active"
                              :key="item"
                              class="cell100 column2"
                            >
                              {{ item }}
                            </td>
                          </tr> -->
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.deposit"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.withdraw"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.total"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.bet"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.nobet"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.facebook"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.google"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.youtube"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.tiktok"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.sms"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                            <tr class="row100 body">
                              <td
                                v-for="item in monthly.friend"
                                :key="item"
                                class="cell100 column2"
                              >
                                {{ Commas(item) }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="table100-lastcol">
                      <table>
                        <thead>
                          <tr class="row100 head">
                            <th class="cell100 column1">
                              รวม
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.regis.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.nodeposit.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              รายการ
                            </td>
                          </tr>

                          <!-- <tr class="row100 body">
                          <td class="cell100 column1">
                            {{
                              monthly.tactive
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }}
                            คน
                          </td>
                                                            .toFixed(2),
                        </tr> -->

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.deposit.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              บาท
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.withdraw.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              บาท
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.total.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              บาท
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.bet.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              บาท
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.nobet.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.facebook.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.google.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.youtube.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.tiktok.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>
                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.sms.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>

                          <tr class="row100 body">
                            <td class="cell100 column1">
                              {{
                                Commas(
                                  monthly.friend.reduce(
                                    (acc, ele) => acc + (!isNaN(ele) ? ele : 0),
                                    0
                                  )
                                )
                              }}
                              คน
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </b-overlay>
        </b-col>

        <b-col md="3">
          <b-card>
            <b-avatar
              variant="light-success"
              class="mb-1"
              size="lg"
            >
              <i class="far fa-users fa-2x" />
            </b-avatar>
            <h3>{{ Commas(today.nobet) }} คน</h3>
            <small>จำนวนลูกค้าแทงวันนี้</small>
          </b-card>

          <b-card>
            <b-avatar
              variant="light-info"
              class="mb-1"
              size="lg"
            >
              <i class="fas fa-user-check fa-2x" />
            </b-avatar>
            <h3>{{ Commas(today.regis) }} คน</h3>
            <small>จำนวนลูกค้าสมัครวันนี้</small>
          </b-card>

          <b-card>
            <b-avatar
              variant="light-primary"
              class="mb-1"
              size="lg"
            >
              <i class="fas fa-wallet fa-2x" />
            </b-avatar>
            <h3>{{ Commas(today.nodeposit) }} คน</h3>
            <small>จำนวนลูกค้าเติมเงินวันนี้</small>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAvatar,
  BRow,
  BCol,
  BCard,
  BOverlay,
  BIconController,
  BFormSelect,
  BButton,
} from 'bootstrap-vue'
// import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
// import VueApexCharts from 'vue-apexcharts'
import momenttz from 'moment-timezone'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    // VueApexCharts,
    // StatisticCardWithAreaChart,
    BOverlay,
    BIconController,
    BFormSelect,
    BButton,
  },
  data() {
    return {
      selectedyear: momenttz().subtract(1, 'days').format('YYYY'),
      selectedmonth: momenttz().subtract(1, 'days').format('M'),
      endDate: Number(
        momenttz().subtract(1, 'days').endOf('month').format('D'),
      ),
      endDateTable:
        Number(momenttz().subtract(1, 'days').endOf('month').format('D')) + 1,
      month: [
        { value: null, text: 'เลือกเดือน' },
        { value: '1', text: 'มกราคม' },
        { value: '2', text: 'กุมภาพันธ์' },
        { value: '3', text: 'มีนาคม' },
        { value: '4', text: 'เมษายน' },
        { value: '5', text: 'พฤษภาคม' },
        { value: '6', text: 'มิถุนายน' },
        { value: '7', text: 'กรกฎาคม' },
        { value: '8', text: 'สิงหาคม' },
        { value: '9', text: 'กันยายน' },
        { value: '10', text: 'ตุลาคม' },
        { value: '11', text: 'พฤศจิกายน' },
        { value: '12', text: 'ธันวาคม' },
      ],
      year: [
        { value: null, text: 'เลือกปี' },
        { value: '2022', text: '2565' },
        { value: '2023', text: '2566' },
        { value: '2024', text: '2567' },
        { value: '2025', text: '2568' },
        { value: '2026', text: '2569' },
        { value: '2027', text: '2570' },
      ],
      show: true,
      showTable: true,
      today: {
        total: 0,
        deamount: 0,
        weamount: 0,
        debet: 0,
        regis: 0,
        nobet: 0,
        nodeposit: 0,
      },
      monthly: {
        regis: [],
        nodeposit: [],
        active: [],
        deposit: [],
        withdraw: [],
        total: [],
        bet: [],
        nobet: [],
        facebook: [],
        google: [],
        youtube: [],
        tiktok: [],
        sms: [],
        friend: [],
      },
      depositSeries: [
        {
          name: 'ฝาก',
          data: [],
        },
      ],
      withdrawSeries: [
        {
          name: 'ถอน',
          data: [],
        },
      ],
      betSeries: [
        {
          name: 'แทง',
          data: [],
        },
      ],
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data').then(response => {
      this.data = response.data
    })
  },
  mounted() {
    this.getToday()
    this.getMonthly()
  },
  methods: {
    kFormatter,
    async getToday() {
      await this.$http
        .get('https://api.rmtlotto.com/api/dashboard/today')
        .then(response => {
          this.show = false
          // console.log(response.data)
          this.today = response.data
          this.depositSeries[0].data = response.data.ddata
          this.withdrawSeries[0].data = response.data.wdata
          this.betSeries[0].data = response.data.bdata
        })
        .catch(error => console.log(error))
    },
    async getMonthly() {
      if (this.selectedmonth) {
        this.showTable = true
        this.ChangeEndDate(this.selectedyear, this.selectedmonth)
        this.ClearMonthly()
        const params = {
          selectedmonth: this.selectedmonth,
          selectedyear: this.selectedyear,
        }
        await this.$http
          .get(
            'https://api.rmtlotto.com/api/dashboard/getmonthly',
            {
              params,
            },
          )
          .then(response => {
            this.showTable = false
            // console.log(response.data)
            // this.monthly = response.data
            this.CalMonthly(response.data)
            // console.log(this.monthly.regis)
          })
          .catch(error => console.log(error))
      }
    },
    ChangeEndDate(selectedyear, selectedmonth) {
      const FindEndDay = `${selectedyear}-${
        selectedmonth < 10 ? 0 + selectedmonth : selectedmonth
      }-01 00:00:00`
      this.endDate = Number(momenttz(FindEndDay).endOf('month').format('D'))
      this.endDateTable = this.endDate + 1
    },
    CalMonthly(Report) {
      // eslint-disable-next-line no-plusplus
      for (let i = 1; i <= this.endDate; i++) {
        // console.log(i)
        const Index = Report.findIndex(({ day }) => day === i)
        if (Index >= 0) {
          this.monthly.regis.push(Report[Index].regis)
          this.monthly.nodeposit.push(Report[Index].nodeposit)
          this.monthly.active.push(Report[Index].active)
          this.monthly.deposit.push(Report[Index].deposit)
          this.monthly.withdraw.push(Report[Index].withdraw)
          this.monthly.total.push(Report[Index].total)
          this.monthly.bet.push(Report[Index].bet)
          this.monthly.nobet.push(Report[Index].nobet)
          this.monthly.facebook.push(Report[Index].facebook)
          this.monthly.google.push(Report[Index].google)
          this.monthly.youtube.push(Report[Index].youtube)
          this.monthly.friend.push(Report[Index].friend)
          this.monthly.tiktok.push(Report[Index].tiktok)
          this.monthly.sms.push(Report[Index].sms)
        } else {
          this.monthly.regis.push('-')
          this.monthly.nodeposit.push('-')
          this.monthly.active.push('-')
          this.monthly.deposit.push('-')
          this.monthly.withdraw.push('-')
          this.monthly.total.push('-')
          this.monthly.bet.push('-')
          this.monthly.nobet.push('-')
          this.monthly.facebook.push('-')
          this.monthly.google.push('-')
          this.monthly.youtube.push('-')
          this.monthly.friend.push('-')
          this.monthly.tiktok.push('-')
          this.monthly.sms.push('-')
        }
      }
    },
    ClearMonthly() {
      this.monthly = {
        regis: [],
        nodeposit: [],
        active: [],
        deposit: [],
        withdraw: [],
        total: [],
        bet: [],
        nobet: [],
        facebook: [],
        google: [],
        youtube: [],
        tiktok: [],
        sms: [],
        friend: [],
      }
    },
    Commas(x) {
      if (Math.round(x) !== x && x > 0) {
        const Num = Number(x)
        const y = Num.toFixed(2)
        return y.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
/*//////////////////////////////////////////////////////////////////
[ Scroll bar ]*/
.js-pscroll {
  position: relative;
  overflow: hidden;
}

.table100 .ps__rail-x {
  z-index: 1010;
  height: 6px;
  background-color: transparent;
  opacity: 1 !important;
  bottom: 10px;
}

.table100 .ps__rail-x::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #ff0000;
  height: 100%;
  width: calc(100% - 30px);
  left: 15px;
  top: 0px;
}

.table100 .ps__rail-x .ps__thumb-x {
  height: 100%;
  bottom: 0;
  background-color: transparent;
  opacity: 1 !important;
}

.table100 .ps__rail-x .ps__thumb-x::before {
  content: "";
  display: block;
  position: absolute;
  background-color: #191919;
  height: 100%;
  width: calc(100% - 30px);
  top: 0;
  left: 15px;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/

.limiter {
  width: 100%;
  margin: 0 auto;
  background: #fa71cd;
  background: -webkit-linear-gradient(bottom, #c471f5, #fa71cd);
  background: -o-linear-gradient(bottom, #c471f5, #fa71cd);
  background: -moz-linear-gradient(bottom, #c471f5, #fa71cd);
  background: linear-gradient(bottom, #c471f5, #fa71cd);
}

.container-table100 {
  max-width: 1366px;
  margin: 0 auto;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 5px 100px;
}

.wrap-table100 {
  width: 100%;
}

/*//////////////////////////////////////////////////////////////////
[ Table ]*/
.table100 {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
  height: 30px;
}

.column1 {
  color: rgb(0, 0, 0);
  background-color: #ffffff;
  width: 80px;
  padding-left: 20px;
}

.column2 {
  width: 120px;
  height: 25px;
  /* padding-left: 5px; */
}

.column3 {
  height: 25px;
  width: 120px;
}

.column4 {
  height: 25px;
  width: 120px;
}

.column5 {
  height: 25px;
  width: 120px;
}

.column6 {
  height: 25px;
  width: 120px;
}

.column7 {
  height: 25px;
  width: 120px;
}

.column8 {
  height: 25px;
  width: 120px;
}

.table100 th {
  padding-top: 20px;
  padding-bottom: 20px;
}

.table100 td {
  padding-top: 16px;
  padding-bottom: 16px;
}

/*==================================================================
[ Fix col ]*/
.table100 {
  width: 100%;
  position: relative;
}

.table100-firstcol {
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  width: 175px;
  top: 0;
  left: 0;
}
.table100-lastcol {
  background-color: #fff;
  position: absolute;
  z-index: 1000;
  width: 155px;
  top: 0;
  right: 0;
}

.table100-firstcol table {
  background-color: #fff;
}

.wrap-table100-nextcols {
  width: 100%;
  overflow: auto;
  padding-left: 180px;
  padding-bottom: 28px;
}

.table100-nextcols table {
  table-layout: fixed;
}

.shadow-table100-firstcol {
  box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -ms-box-shadow: 8px 0px 10px 0px rgba(0, 0, 0, 0.05);
}

.table100-firstcol table {
  background-color: transparent;
}

/*==================================================================
[ Ver1 ]*/

.table100.ver1 th {
  font-size: 14px;
  color: #ffffff;
  background: #233447;
  line-height: 0.1;
  text-transform: uppercase;
}

.table100.ver1 td {
  font-size: 0.9rem;
  line-height: 0.1;
}

.table100.ver1 .table100-firstcol td {
  color: #000000;
}

.table100.ver1 .table100-nextcols td {
  color: #000000;
}

.table100.ver1 tr {
  border-bottom: 0.1px solid #6b6b6b4f;
}
</style>
